import { http } from "../api/http.provider";



export const getEmailEnabledApi = (): any => {
    let url = `api/v1/config/email-enabled`;
    return http.get(url, { withCredentials: true }).then(({ data }) => data);
  };
  
export const toggleEmailEnabled = (): any => {
    let url = `api/v1/config/toggle-email`;
    return http.post(url,{}, { withCredentials: true }).then(({ data }) => data);
}