import { IFileActionResponse, IFilesResponse } from '../models/response';
import { http } from '../../../api/http.provider';

export const uploadFile = (): Promise<IFileActionResponse> => {
  return {} as Promise<IFileActionResponse>;
};

export const createFolder = (foldername: string, user: string): Promise<IFileActionResponse> => {
  let url = `api/v1/files/create-folder/${user}`;
  return http.post(url, { foldername: foldername }, { withCredentials: true }).then(({ data }) => data);
};

export const getFolderContent = (path: string): Promise<IFilesResponse> => {
  let url = `api/v1/files/get-folder/${path}`;
  return http.get(url, { withCredentials: true }).then(({ data }) => data);
};

export const deleteFiles = (filenames: string[], user: string): Promise<IFileActionResponse> => {
  let url = `api/v1/files/delete/${user}`;
  return http.post(url, { files: filenames }, { withCredentials: true }).then(({ data }) => data);
};

export const uploadFiles = (files: any, path: string): Promise<IFileActionResponse> => {
  let url = `api/v1/files/upload/${path}`;
  return http
    .post(url, files, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    })
    .then(({ data }) => data);
};
