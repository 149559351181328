import { Button, Form, Input, InputNumber, message, Modal } from 'antd';
import styles from './CreatePlantContainer.module.scss';
import { createPlant } from './services/createPlant.service';

interface ICreatePlantContainerProps {
  visible: boolean;
  setVisible: Function;
  setChanges: Function;
  changes: boolean;
}

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};

const CreatePlantContainer: React.FC<ICreatePlantContainerProps> = ({ visible, setVisible, changes, setChanges }) => {
  const [form] = Form.useForm();

  const handleCreate = (values: any) => {
    createPlant(values)
      .then((res) => {
        if (res.plant) {
          message.success('Planta creada con exito!');
          setChanges(!changes);
          setVisible(false);

          return;
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error Comunicándose con el Servidor!');
      });
  };

  const handleClose = () => {
    form.resetFields();
    setVisible(false);
  };

  return (
    <Modal closable onCancel={handleClose} title="Crear Planta" visible={visible} footer={null}>
      <Form {...layout} form={form} onFinish={handleCreate}>
        <Form.Item
          label="Nombre"
          name="name"
          rules={[
            {
              required: true,
              message: 'Ingresa el nombre de la planta'
            }
          ]}
        >
          <Input type="text"></Input>
        </Form.Item>

        <Form.Item
          label="Dirección"
          name="address"
          rules={[
            {
              required: true,
              message: 'Ingresa la dirección!'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Ingresa el email de la planta!',
              type: 'email'
            }
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          label="Telefono"
          name="phone"
          rules={[
            {
              required: true,
              message: 'Ingresa el telefono de la planta!'
            }
          ]}
        >
          <InputNumber max={999999999} min={100000} controls={false} />
        </Form.Item>

        <Form.Item className={styles.formButtons}>
          <Button className={styles.formCancel} onClick={handleClose}>
            Cancelar
          </Button>
          <Button className={styles.formCreate} type="primary" htmlType="submit">
            Crear
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreatePlantContainer;
