import React from 'react';
import { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { jwtToken, decodedJwt } from '../../helpers/jwt.helper';
import { getCurrentUser, isAdmin, isQualityDepartment, isSuperAdmin } from '../../helpers/user.helper';

interface IPrivateRouteProps {
  requireUser: boolean;
  requireQualityDeparment?: boolean;
  children: any;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ children, requireUser, requireQualityDeparment }) => {
  let loginUrl = process.env.REACT_APP_LOGIN_URL || '/login';
  const [redirectUrl, setRedirectUrl] = useState('/');
  const { user } = useParams();
  const [isAuthorized, setIsAuthorized] = useState(true);

  useEffect(() => {
    checkAuthentication();
  }, []);

  const checkAuthentication = () => {
    const token = jwtToken();

    if (!token) {
      setIsAuthorized(false);
      setRedirectUrl(loginUrl);
      return;
    }

    try {
      var tk = decodedJwt();
      if (tk === null) {
        setIsAuthorized(false);
        setRedirectUrl(loginUrl);
        return;
      }

      if (requireUser == true && getCurrentUser()?.username != user && !isAdmin()) {
        setIsAuthorized(false);
        setRedirectUrl('/');
      }
      if (requireQualityDeparment && !isQualityDepartment()) {
        setIsAuthorized(false);
        setRedirectUrl('/');
      }
    } catch (err) {
      console.error(`Invalid or malformed token found: ${err}`);
      setIsAuthorized(false);
      setRedirectUrl(loginUrl);
    }
  };

  return isAuthorized ? children : <Navigate to={redirectUrl} />;
};

export default PrivateRoute;
