import { Button, Form, Input, Modal, message, Select, InputNumber } from 'antd';
import { Option } from 'antd/lib/mentions';
import { isSuperAdmin } from '../../helpers/user.helper';
import { createUser } from './services/createUser.service';
import styles from './CreateUserContainer.module.scss';

interface CreateUserContainerProps {
  visible: boolean;
  setVisible: Function;
  setChanges: Function;
  changes: boolean;
}

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};

const CreateUserContainer: React.FC<CreateUserContainerProps> = (props) => {
  const [form] = Form.useForm();

  const handleCreate = (values: any) => {
    createUser(values)
      .then((res) => {
        if (res.user) {
          message.success('Usuario creado con exito!');
          props.setChanges(!props.changes);
          props.setVisible(false);
          form.resetFields();
          return;
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error Comunicándose con el Servidor!');
      });
  };

  const handleClose = () => {
    form.resetFields();
    props.setVisible(false);
  };

  const onRoleChange = (value: string) => {
    switch (value) {
      case 'Productor':
        form.setFieldsValue({
          role: 0
        });
        return;

      case 'Administrador':
        form.setFieldsValue({
          role: 1
        });
        return;
    }
  };

  return (
    <Modal closable onCancel={handleClose} title="Crear Usuario" visible={props.visible} footer={null}>
      <Form {...layout} form={form} onFinish={handleCreate}>
        <Form.Item
          label="Usuario"
          name="username"
          rules={[
            {
              required: true,
              message: 'Ingresa el nombre de usuario!'
            }
          ]}
        >
          <Input title="Nombre Carpeta" type="text"></Input>
        </Form.Item>
        <Form.Item
          name="password"
          label="Contraseña"
          rules={[
            {
              required: true,
              message: 'Ingresa una contraseña!'
            }
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirma Contraseña"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Confirma tu contraseña!'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('Ambas contraseñas no coinciden!'));
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Nombre"
          name="name"
          rules={[
            {
              required: true,
              message: 'Ingresa el nombre!'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Rut"
          name="rut"
          rules={[
            {
              required: true,
              message: 'Ingresa el rut del usuario!'
            }
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Ingresa el email del usuario!',
              type: 'email'
            }
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item label="Dirección" name="address">
          <Input></Input>
        </Form.Item>
        <Form.Item label="Teléfono" name="phone">
          <InputNumber max={999999999} min={100000} controls={false} />
        </Form.Item>

        {isSuperAdmin() ?? (
          <Form.Item
            label="Rol"
            name="role_change"
            rules={[
              {
                required: true,
                message: 'Ingresa el rol del usuario'
              }
            ]}
          >
            <Select placeholder="Selecciona el rol del usuario" onChange={onRoleChange} allowClear>
              <Option value="Productor">Productor</Option>
              <Option value="Administrador">Administrador</Option>
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label="Rol"
          name="role"
          noStyle
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Ingresa el rol del usuario'
            }
          ]}
        ></Form.Item>

        <Form.Item className={styles.formButtons}>
          <Button className={styles.formCancel} onClick={handleClose}>
            Cancelar
          </Button>
          <Button className={styles.formCreate} type="primary" htmlType="submit">
            Crear
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUserContainer;
