import { http } from '../../../api/http.provider';
import { ICommonUserResponse } from '../models/response';

export const activateUser = (id: number): Promise<ICommonUserResponse> => {
  let url = `api/v1/users/activate`;
  return http.post(url, { id: id }, { withCredentials: true }).then(({ data }) => data);
};

export const deactivateUser = (id: number): Promise<ICommonUserResponse> => {
  let url = `api/v1/users/deactivate`;
  return http.post(url, { id: id }, { withCredentials: true }).then(({ data }) => data);
};

export const editUser = (user: any): Promise<ICommonUserResponse> => {
  let url = 'api/v1/users/edit';
  return http.post(url, user, { withCredentials: true }).then(({ data }) => data);
};
