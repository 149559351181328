import { http } from '../../../api/http.provider';
import { ILot } from '../../../models/lot';
import { ICreateConsolidadosResponse, IDeleteLotResponse, IGetLotsResponse, ILotResponse } from '../models/response';

export const getLots = (): Promise<IGetLotsResponse> => {
  let url = 'api/v1/lots/all';
  return http.get(url, { withCredentials: true }).then(({ data }) => data);
};

export const linkAnalysis = (file: any, lot_id: number): Promise<ILotResponse> => {
  let url = `api/v1/lots/link-analysis/${lot_id}`;
  return http
    .post(url, file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    })
    .then(({ data }) => data);
};

export const editLot = (values: any): Promise<ILotResponse> => {
  let url = 'api/v1/lots/update';
  return http.post(url, values, { withCredentials: true }).then(({ data }) => data);
};

export const deleteLot = (lot_id: number): Promise<IDeleteLotResponse> => {
  let url = `api/v1/lots/delete/${lot_id}`;
  return http.get(url, { withCredentials: true }).then(({ data }) => data);
};

export const deleteAnalysis = (lot_id: number): Promise<IDeleteLotResponse> => {
  let url = `api/v1/lots/delete-analysis/${lot_id}`;
  return http.get(url, { withCredentials: true }).then(({ data }) => data);
};

export const createConsolidados = (): Promise<ICreateConsolidadosResponse> => {
  let url = 'api/v1/pdf/generate-consolidados';
  return http.get(url, { withCredentials: true }).then(({ data }) => data);
};
