enum Fixed {
  Top = 'Top',
  Bottom = 'Bottom'
}
enum Sticky {
  Top = 'Top',
  Bottom = 'Bottom'
}
enum Expands {
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
  Xl = 'xl'
}
enum Align {
  Start = 'start',
  End = 'end',
  Around = 'around',
  Center = 'center',
  Between = 'between'
}

export { Fixed, Sticky, Expands, Align };
