import { http } from '../api/http.provider';

interface IGetFileResponse {
  url: string;
}

export const getFile = (path: string): Promise<IGetFileResponse> => {
  let url = `api/v1/files/get-file/${path}`;
  return http.get(url, { withCredentials: true }).then(({ data }) => data);
};
