import { useState } from 'react';
import { FcFolder } from 'react-icons/fc';
import { RiFileExcel2Line } from 'react-icons/ri';
import {
  FaRegFileAlt,
  FaRegFileArchive,
  FaRegFileCode,
  FaRegFileImage,
  FaRegFilePdf,
  FaRegFileWord
} from 'react-icons/fa';
import styles from './FileCard.module.scss';
import { IFile } from '../../models/file';

interface FileCardProps {
  file: IFile;
  selected: any;
  setSelected: any;
  handleGetFile: Function;
  handleOpenFolder: Function;
  singleClick: boolean;
}

function fileIcon(extension: string) {
  switch (extension) {
    case 'pdf':
      return <FaRegFilePdf className={styles.icon40} />;
    case 'xlsx':
    case 'xls':
    case 'xlsm':
      return <RiFileExcel2Line className={styles.icon40} />;
    case 'doc':
    case 'docx':
      return <FaRegFileWord className={styles.icon40} />;

    case 'jpg':
    case 'png':
    case 'jpeg':
    case 'gif':
      return <FaRegFileImage className={styles.icon40} />;

    case 'rar':
    case 'zip':
      return <FaRegFileArchive className={styles.icon40} />;

    case 'html':
    case 'css':
    case 'py':
    case 'js':
    case 'jsx':
    case 'tsx':
    case 'ts':
      return <FaRegFileCode className={styles.icon40} />;

    case 'folder':
      return <FcFolder className={styles.icon60} />;
    default:
      return <FaRegFileAlt className={styles.icon40} />;
  }
}

const FileCard: React.FC<FileCardProps> = ({
  file,
  selected,
  setSelected,
  handleGetFile,
  handleOpenFolder,
  singleClick
}) => {
  const [selectedState, setSelectedState] = useState(false);

  const filterSelected = (selectedFilename: string) => {
    if (selectedFilename !== file.filename) {
      return true;
    }
    return false;
  };

  const changeSelected = () => {
    if (selectedState) {
      var selectedFiles = selected.filter((selected: IFile) => filterSelected(selected.filename));
      setSelectedState(!selectedState);
      setSelected(selectedFiles);
      return;
    }

    selected.push(file);
    setSelected(selected);
    setSelectedState(!selectedState);
  };

  const handleAction = () => {
    if (file.extension === 'folder') {
      return handleOpenFolder(file.path);
    }
    return handleGetFile(file.path);
  };

  return (
    <div>
      {singleClick ? (
        <div className={selectedState ? `${styles.card} ${styles.selected}` : styles.card} onClick={handleAction}>
          <div className={styles.iconContainer}>{fileIcon(file.extension)}</div>
          <div className={styles.cardBody}>
            <h2>{file.filename}</h2>
          </div>
        </div>
      ) : (
        <div
          className={selectedState ? `${styles.card} ${styles.selected}` : styles.card}
          onClick={changeSelected}
          onDoubleClick={handleAction}
        >
          <div className={styles.iconContainer}>{fileIcon(file.extension)}</div>
          <div className={styles.cardBody}>
            <h2>{file.filename}</h2>
          </div>
        </div>
      )}
    </div>
  );
};
export default FileCard;
