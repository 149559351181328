import { FaFolder, FaHeartbeat, FaToggleOff, FaToggleOn, FaTruckMoving } from 'react-icons/fa';
import { getCurrentUser } from '../../../../helpers/user.helper';
import { NavbarProps } from './resources/NavBar.interface';
import { NavList, Align, NavItem, UserBox, UserBoxItem } from './components/index';
import { clearSessionStorage } from '../../../../helpers/cookies.helper';
import NavBrand from './components/nav-brand/NavBrand';
import { globalService } from '../../../../services/global.service';
import { useEffect, useState } from 'react';
import ChangePasswordModal from '../../../change-password-modal/ChangePasswordModal';
import { useNavigate } from 'react-router-dom';
import { getEmailEnabled, setEmailEnabled } from '../../../../helpers/session.helper';
import { getEmailEnabledApi, toggleEmailEnabled } from '../../../../services/config.service';
import { Alert, Switch } from 'antd';

const styles = require('./Navbar.module.scss');

const Navbar: React.FC<NavbarProps> = ({ backgroundColor = '#001529' }) => {
  const Styles = {
    backgroundColor: backgroundColor
  };

  const [showChangePassword, setShowChangePassword] = useState(false);
  const [alertVisible, setAlertVisible] = useState(true);
  const [enabled, setEnabled] = useState<boolean | undefined>(undefined);
  const currentUser = getCurrentUser();
  const navigate = useNavigate();

  const handlerCloseSession = () => {
    clearSessionStorage();
    return;
  };

  const handleChangePassword = () => {
    setShowChangePassword(true);
  };

  const toggleEmail = () => {
    toggleEmailEnabled().then((res: any) => {
      if(res.enabled === undefined){
        setEnabled(false);
        return;
      }
      setEnabled(res.enabled);
    }
    ).catch((err: any) => {
      console.log(err);
    }
    );

  }

  useEffect(() => {
    if(enabled !== undefined){
      return;
    }
    getEmailEnabledApi().then((res: any) => {
      if(res.enabled === undefined){
        setEnabled(false);
        return;
      }
      setEnabled(res.enabled);
    }
    ).catch((err: any) => {
      console.log(err);
    }
    );

  }, [])

  return (
    <nav className={`${styles.nav} ${styles.navbar}`} style={Styles}>
      <NavBrand className={styles.logo} />
      <NavList alignment={Align.Start}>
        <NavItem
          onClick={() => {
            navigate(`/files/${getCurrentUser()?.username}`);
          }}
          active={true}
        >
          <FaFolder />
          Archivos
        </NavItem>
        <NavItem
          onClick={() => {
            navigate(`/lots/${getCurrentUser()?.username}`);
          }}
          active={true}
        >
          <FaTruckMoving />
          Lotes
        </NavItem>
       {currentUser?.role === 1 || currentUser?.role === 2 ? (
        <>
         <NavItem
         onClick={toggleEmail}
         active={true}
       >
         
         <Switch checked={enabled} onChange={toggleEmail} />
         Correos
       </NavItem>
       {enabled !== undefined && !enabled ? <Alert message="El envío de correos electrónicos se encuentra deshabilitado" type="warning" showIcon={true} /> : null}</>
       
       ) : null}
      </NavList>
      <ChangePasswordModal
        visible={showChangePassword}
        setVisible={setShowChangePassword}
        userId={currentUser?.id ?? -1}
      />
      <UserBox name={`${currentUser?.name.split(' ')[0]}`}>
        <UserBoxItem title={'Cambiar Contraseña'} action={handleChangePassword} />
        <UserBoxItem title={'Cerrar Sesión'} href={`/login`} action={handlerCloseSession} />
      </UserBox>
    </nav>
  );
};

export default Navbar;
