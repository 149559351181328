import { Button, Descriptions, message, Modal, Table, Tabs, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiOutlineDownload, AiOutlineFilePdf } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { TableSearch } from '../../components';
import { isQualityDepartment } from '../../helpers/user.helper';
import { ILot } from '../../models/lot';
import { IPlantInfo } from '../../models/plant';
import { IUser } from '../../models/user';
import { getFile } from '../../services/files.service';
import { globalService } from '../../services/global.service';
import { getUserLots } from './services/getLots';
import { createConsolidados } from './services/viewUserLots.service';
import styles from './ViewUserLotsContainer.module.scss';

const ViewUserLotsContainer: React.FC = () => {
  const columns = [
    {
      title: 'Guía de Despacho',
      dataIndex: 'guide_number',
      fixed: 'left'
    },
    {
      title: 'Lote',
      dataIndex: 'identifier'
    },
    {
      title: 'Productor',
      dataIndex: 'producer',
      render: (producer: IUser) => {
        return producer.name;
      }
    },
    {
      title: 'Fecha Recepción',
      dataIndex: 'reception_date'
    },
    {
      title: 'Kilogramos Ingresados',
      dataIndex: 'total_wet_kgs',
      width: 'auto'
    },
    {
      title: 'Tipo Envase',
      dataIndex: 'packing_type',
      width: 'auto'
    },
    {
      title: 'Cantidad Envases',
      dataIndex: 'amount_of_packages',
      width: 'auto'
    },
    {
      title: 'Planta Proceso',
      dataIndex: 'plant',
      render: (plant: IPlantInfo) => {
        return plant.name;
      }
    },
    {
      title: 'Variedad',
      dataIndex: 'variety'
    },
    {
      title: 'Nombre Transportista',
      dataIndex: 'carrier_name'
    },
    {
      title: 'Patente Camión',
      dataIndex: 'license_plate'
    },
    {
      title: 'Patente Carro',
      dataIndex: 'truck'
    },
    {
      title: 'Kilogramos Secos',
      dataIndex: 'total_dried_kgs'
    },
    {
      title: 'Humedad Ingreso',
      dataIndex: 'humidity_percentage_in',
      render: (value: number) => {
        if (value) {
          return String(value).concat(' %');
        }
      }
    },
    {
      title: 'Humedad Salida',
      dataIndex: 'humidity_percentage_out',
      render: (value: number) => {
        if (value) {
          return String(value).concat(' %');
        }
      }
    },
    {
      title: 'Rendimiento Secado',
      dataIndex: 'drying_process_efficiency',
      render: (value: number) => {
        if (value) {
          return String(value).concat(' %');
        }
      }
    },
    {
      title: 'Fecha Comienzo Secado',
      dataIndex: 'drying_started_at'
    },
    {
      title: 'Fecha Término Secado',
      dataIndex: 'drying_finished_at'
    },
    {
      title: 'Tiempo de Secado',
      dataIndex: 'drying_total_time',
      render: (value: string) => {
        if (value) {
          value = value.replace('days', 'Días');
          value = value.replace('day', 'Día');
        }
        return value;
      }
    },
    {
      title: 'Análisis de Calidad',
      dataIndex: 'quality_analysis',
      render: (path: string) => {
        if (!path) {
          return 'No Cargado';
        }
        return (
          <Button key={path} onClick={() => getAnalysis(path)} type="primary">
            Descargar
          </Button>
        );
      }
    }
  ];

  const { user } = useParams();
  const [lots, setLots] = useState<ILot[]>([]);
  const [filteredLots, setFilteredLots] = useState<ILot[]>([]);
  const [selectedLot, setSelectedLot] = useState<ILot>({} as ILot);
  const [viewInfo, setViewInfo] = useState(false);
  const [showConsolidados, setShowConsolidados] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getUserLots(user ? user : '')
      .then((res) => {
        if (res.lots) {
          var sortedLots = sortLots(res.lots);
          setLots(sortedLots);
          setFilteredLots(sortedLots);
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al Comunicárse con el Servidor!');
      });
  }, []);

  const handleViewInfo = (id: number) => {
    lots.map((lot) => {
      if (lot.id === id) {
        setSelectedLot(lot);
        setViewInfo(true);
        return lot;
      }
    });
  };

  const sortLots = (lots: ILot[]) => {
    lots = lots.sort((a, b) => {
      var guide_a = parseInt(a.guide_number);
      var guide_b = parseInt(b.guide_number);
      if (guide_a < guide_b) {
        return -1;
      }
      return 1;
    });
    return lots;
  };

  const getAnalysis = (path: string) => {
    var newWindow = window.open();
    newWindow?.blur();

    getFile(path)
      .then((res) => {
        if (res.url) {
          if (newWindow === null) {
            return;
          }
          newWindow.location = res.url;
          newWindow.focus();
          return;
        }

        message.error('Error al Conseguir Informe');
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error Comunicándose con el Servidor');
      });
  };

  const filterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = e.target.value;
    const filteredData = lots.filter(
      (entry) => entry.producer.name.includes(currValue) || String(entry.identifier).includes(currValue)
    );
    setFilteredLots(filteredData);
  };

  const handleCreateConsolidados = () => {
    createConsolidados(user ?? '')
      .then((res) => {
        if (res.success) {
          message.success('Exito Creando Consolidados!');
          return;
        }
        message.error('Error Creando Consolidados!');
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error Comunicandose Con El Servidor!');
      });
  };

  const currentYear = new Date().getFullYear();

  const checkYear = (receptionDate: string, year: number) => {
    const [dateStr, timeStr] = receptionDate.split(' ');
    const [day, month, receptionYear] = dateStr.split('/');
    const yearNum = parseInt(receptionYear, 10);
    if (yearNum === year) {
      console.log("True");
      return true;
    }
    return false;


  }

  const items = Array.from(
    { length: currentYear - 2021 },
    (_, index) => {
      const year = 2022 + index;
      return {
        key: year,
        label: `Temporada ${year}`,
        children: <TableSearch
        onRow={(record: ILot, _: number) => {
          return {
            onClick: (e: MouseEvent) => {
              handleViewInfo(record.id);
            }
          };
        }}
        title={'Lotes'}
        columns={columns}
        data={filteredLots.filter((lot) => checkYear(lot.reception_date, year))}
        filter={filterSearch}
        noMargin={true}
      >
        <>
          <Tooltip title={'Descargar Excel'}>
            <Button
              className={styles.add}
              type="primary"
              href={`${globalService.apiUrl}/api/v1/lots/generate-user-excel/${user}`}
            >
              <AiOutlineDownload className={styles.icon} />
            </Button>
          </Tooltip>
          {isQualityDepartment() ? (
            <Tooltip title={'Generar Consolidados Secado'}>
              <Button className={styles.add} type="primary" onClick={() => setShowConsolidados(true)}>
                <AiOutlineFilePdf className={styles.icon} />
              </Button>
            </Tooltip>
          ) : (
            <></>
          )}
        </>
      </TableSearch>,
      };
    }
  );

  return (
    <>
    <div className={styles.tabs}>
      <Tabs defaultActiveKey="1" >
        {items.reverse().map((item) => (
          <Tabs.TabPane tab={item.label} key={item.key}>
            {item.children}
          </Tabs.TabPane>
        ))}

      </Tabs>
      </div>
      

      <Modal
        title="Crear Consolidados Secado"
        visible={showConsolidados}
        okText="Crear"
        onOk={handleCreateConsolidados}
        onCancel={() => setShowConsolidados(false)}
      >
        <h4>Esta seguro que desea crear todos los consolidados de rendimiento secado?</h4>
      </Modal>

      <Modal visible={viewInfo} closable onCancel={() => setViewInfo(false)} footer={null} width={600}>
        <Descriptions
          className={styles.description}
          layout={'horizontal'}
          title={`Información del Lote`}
          column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item label="Número de Lote">{selectedLot.identifier ?? ''}</Descriptions.Item>
          <Descriptions.Item label="Productor">{selectedLot.producer?.name ?? ''}</Descriptions.Item>
          <Descriptions.Item label="Fecha Recepción">{selectedLot.reception_date ?? ''}</Descriptions.Item>
          <Descriptions.Item label="Variedad">{selectedLot.variety ?? ''}</Descriptions.Item>
          <Descriptions.Item label="Planta de Proceso">{selectedLot.plant?.name ?? ''}</Descriptions.Item>
          <Descriptions.Item label="Kilogramos Ingresados">{selectedLot.total_wet_kgs ?? ''}</Descriptions.Item>
          <Descriptions.Item label="Tipo Envase">{selectedLot.packing_type ?? ''}</Descriptions.Item>
          <Descriptions.Item label="Cantidad Envases">{selectedLot.amount_of_packages ?? ''}</Descriptions.Item>
          <Descriptions.Item label="% Humedad Ingreso">{selectedLot.humidity_percentage_in ?? ''}</Descriptions.Item>
          <Descriptions.Item label="% Humedad Salida">{selectedLot.humidity_percentage_out ?? ''}</Descriptions.Item>
          <Descriptions.Item label="Kilogramos Secos">{selectedLot.total_dried_kgs ?? ''}</Descriptions.Item>
          <Descriptions.Item label="Rendimiento Secado">
            {selectedLot.drying_process_efficiency ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="Fecha Comienzo Secado">{selectedLot.drying_started_at ?? ''}</Descriptions.Item>
          <Descriptions.Item label="Fecha Término Secado">{selectedLot.drying_finished_at ?? ''}</Descriptions.Item>
          <Descriptions.Item label="Tiempo Total Secado">
            {selectedLot.drying_total_time?.replace('days', 'Días') ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="Guía de Despacho">{selectedLot.guide_number ?? ''}</Descriptions.Item>
          <Descriptions.Item label="Transportista">{selectedLot.carrier_name ?? ''}</Descriptions.Item>
          <Descriptions.Item label="Patente Camión">{selectedLot.truck ?? ''}</Descriptions.Item>
          <Descriptions.Item label="Patente Carro">{selectedLot.license_plate ?? ''}</Descriptions.Item>
        </Descriptions>

        <div className={styles.actionButtons}>
          {selectedLot.quality_analysis ? (
            <Button className={styles.button} type="primary" onClick={() => getAnalysis(selectedLot.quality_analysis)}>
              Descargar Informe Calidad
            </Button>
          ) : (
            <Button className={styles.button} type="primary">
              Informe Calidad No Cargado
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ViewUserLotsContainer;
