import React, { FunctionComponent, useState, useLayoutEffect } from 'react';
import { NavListProps } from '../../resources/NavBar.interface';
import { Align } from '../../resources/NavBar.enum';

const styles = require('./NavList.module.scss');
const NavList: FunctionComponent<NavListProps> = ({ children, alignment = Align.Start }) => {
  const [isAlign, setAlign] = useState(alignment);
  useLayoutEffect(() => {
    setAlign(styles[`${alignment}`]);
  }, [alignment]);
  return <ul className={`${isAlign} ${styles[`navList`]}`}>{children}</ul>;
};
export default NavList;
