import { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Select, Button, message, Divider } from 'antd';

import styles from './CreateLotContainer.module.scss';
import { getProducers } from '../../services/getUsers.service';
import { IUserInfo } from '../../models/user';
import { getPlants } from '../../services/getPlants.service';
import { IPlantInfo } from '../../models/plant';
import { postLot } from './services/createLot.service';
import CreateUserContainer from '../create-user-container/CreateUserContainer';
import { Header } from 'antd/lib/layout/layout';
import CreatePlantContainer from '../create-plant-container/CreatePlantContainer';

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 8
    }
  },
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 16
    }
  }
};

const CreateLotContainer: React.FC = () => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState<Array<IUserInfo>>([]);
  const [plants, setPlants] = useState<Array<IPlantInfo>>([]);
  const [triggerUserFetch, setTriggerUserFetch] = useState(false);
  const [triggerPlantFetch, setTriggerPlantFetch] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showPlantModal, setShowPlantModal] = useState(false);

  useEffect(() => {
    getProducers()
      .then((res) => {
        if (res.users) {
          setUsers(res.users);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [triggerUserFetch]);

  useEffect(() => {
    getPlants()
      .then((res) => {
        if (res.plants) {
          setPlants(res.plants);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [triggerPlantFetch]);

  const onFinish = (values: any) => {
    postLot(values)
      .then((res) => {
        if (res.lot) {
          message.success(`Exito creando el lote ${res.lot.identifier}`);
          form.resetFields();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al comunicarse con  el servidor!');
      });
  };

  const createProductor = () => {
    setShowUserModal(true);
  };

  const createPlant = () => {
    setShowPlantModal(true);
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    Object.keys(changedValues).forEach((key: any) => {
      if (changedValues[key] === null) {
        form.setFieldsValue({ [key]: undefined });
      }
      return changedValues;
    });

    return allValues;
  };

  return (
    <div className={styles.container}>
      <Header className={styles.header}>
        <h3 style={{ fontWeight: 600 }}>Crear Lote</h3>
        <div className={styles.topButtonsContainer}>
          <Button className={styles.createEntity} type="primary" onClick={createProductor}>
            Crear Productor
          </Button>
          <Button className={styles.createEntity} type="primary" onClick={createPlant}>
            Crear Planta
          </Button>
        </div>
      </Header>
      <Divider className={styles.divider} />

      <div className={styles.formContainer}>
        <Form
          {...formItemLayout}
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          onValuesChange={onValuesChange}
          labelWrap
          className={styles.form}
        >
          <Form.Item
            name="identifier"
            label="Número de Recepción"
            rules={[
              {
                required: true,
                message: 'Ingresa el numero de recepción'
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="producer_id"
            label="Productor"
            rules={[
              {
                required: true,
                message: 'Ingresa el usuario productor del lote'
              }
            ]}
          >
            <Select
              showSearch
              placeholder="Selecciona un productor"
              optionFilterProp="children"
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {users.map((user, index) => {
                return (
                  <Option key={user.id} value={user.id}>
                    {user.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="reception_date"
            label="Fecha de Recepción"
            rules={[
              {
                required: true,
                message: 'Debe ingresar la fecha con formato DD/MM/AAAA HH:mm!',
                pattern: new RegExp(
                  /^([1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/\d\d\d\d [012]{0,1}[0-9]:[0-5][0-9]$/
                )
              }
            ]}
          >
            <Input placeholder="DD/MM/AAAA HH:mm" />
          </Form.Item>
          <Form.Item
            name="plant_id"
            label="Planta de Procesos"
            rules={[
              {
                required: true,
                message: 'Ingresa la planta donde ingreso el lote'
              }
            ]}
          >
            <Select
              showSearch
              placeholder="Selecciona un productor"
              optionFilterProp="children"
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {plants.map((plant, index) => {
                return (
                  <Option key={plant.id} value={plant.id}>
                    {plant.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="variety"
            label="Variedad"
            rules={[
              {
                required: true,
                message: 'Ingresa la variedad de la fruta del lote'
              }
            ]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            name="total_wet_kgs"
            label="Kilogramos Humedos Ingresados"
            rules={[
              {
                required: true,
                message: 'Ingresa la cantidad de kilos recibidos'
              }
            ]}
          >
            <InputNumber min={0} max={999999999} controls={false}></InputNumber>
          </Form.Item>
          <Form.Item
            name="packing_type"
            label="Tipo Envase"
            rules={[
              {
                required: true,
                message: 'Ingresa el tipo de envase!'
              }
            ]}
          >
            <Select>
              <Option key="option1" value="Maxisacos">
                Maxisacos
              </Option>
              <Option key="option2" value="Bins">
                Bins
              </Option>
              <Option key="option3" value="Bateas">
                Bateas
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="amount_of_packages"
            label="Cantidad de Envases"
            rules={[
              {
                required: true,
                message: 'Ingresa la cantidad de envases recibidos!'
              }
            ]}
          >
            <InputNumber min={0} max={999999999} controls={false} decimalSeparator={','}></InputNumber>
          </Form.Item>

          <Form.Item
            name="guide_number"
            label="Guía de Despacho"
            rules={[
              {
                required: true,
                message: 'Ingresa el numero de la guia de despacho'
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="humidity_percentage_in"
            label="Porcentaje de Humedad Ingreso"
            rules={[
              {
                required: true,
                message: 'Ingresa el porcentaje con maximo 2 decimales'
              }
            ]}
          >
            <InputNumber min={0} max={100} controls={false} decimalSeparator={','}></InputNumber>

            {/* <InputNumber<number>
              min={0}
              max={100}
              controls={false}
            ></InputNumber> */}
          </Form.Item>
          <Form.Item name="humidity_percentage_out" label="Porcentaje de Humedad Salida">
            <InputNumber min={0} max={100} controls={false} decimalSeparator={','}></InputNumber>
          </Form.Item>
          <Form.Item
            name="total_dried_kgs"
            label="Kilogramos Secos Salida"
            tooltip="Utiliza el punto para numeros decimales 3.14"
          >
            <InputNumber min={0} max={999999999} controls={false}></InputNumber>
          </Form.Item>
          <Form.Item name="carrier_name" label="Nombre Transportista">
            <Input />
          </Form.Item>
          <Form.Item name="license_plate" label="Patente Camion">
            <Input />
          </Form.Item>
          <Form.Item name="truck" label="Patente Carro">
            <Input />
          </Form.Item>
          <Form.Item
            name="drying_started_at"
            label="Hora de Comienzo Secado"
            rules={[
              {
                message: 'Debe ingresar la fecha con formato DD/MM/AAAA HH:mm!',
                pattern: new RegExp(
                  /^([1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/\d\d\d\d [012]{0,1}[0-9]:[0-5][0-9]$/
                )
              }
            ]}
          >
            <Input placeholder="DD/MM/AAAA HH:mm" />
          </Form.Item>
          <Form.Item
            name="drying_finished_at"
            label="Fecha de Término Secado"
            rules={[
              {
                message: 'Debe ingresar la fecha con formato DD/MM/AAAA HH:mm!',
                pattern: new RegExp(
                  /^([1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/\d\d\d\d [012]{0,1}[0-9]:[0-5][0-9]$/
                )
              }
            ]}
          >
            <Input placeholder="DD/MM/AAAA HH:mm" />
          </Form.Item>

          <Form.Item className={styles.createButton}>
            <Button type="primary" htmlType="submit">
              Crear
            </Button>
          </Form.Item>
        </Form>
        <CreateUserContainer
          visible={showUserModal}
          setVisible={setShowUserModal}
          setChanges={setTriggerUserFetch}
          changes={triggerUserFetch}
        />
        <CreatePlantContainer
          visible={showPlantModal}
          setVisible={setShowPlantModal}
          setChanges={setTriggerPlantFetch}
          changes={triggerPlantFetch}
        />
      </div>
    </div>
  );
};

export default CreateLotContainer;
