import { Button, Descriptions, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { ILot } from '../../../../models/lot';
import styles from './AdministrateLotModal.module.scss';

interface IAdministrateLotModalProps {
  visible: boolean;
  setVisible: Function;
  handleEdit: React.MouseEventHandler;
  handleUploadAnalysis: React.MouseEventHandler;
  handleDeleteAnalysis: React.MouseEventHandler;
  handleGetAnalysis: Function;
  lot: ILot;
  handleDelete: React.MouseEventHandler;
}

const AdministrateLotModal: React.FC<IAdministrateLotModalProps> = ({
  visible,
  setVisible,
  handleEdit,
  lot,
  handleUploadAnalysis,
  handleDeleteAnalysis,
  handleGetAnalysis,
  handleDelete
}) => {
  const handleClose = () => {
    setVisible(false);
  };

  return (
    <Modal visible={visible} closable onCancel={handleClose} footer={null} width={600}>
      <Descriptions
        className={styles.description}
        layout={'horizontal'}
        title={`Información del Lote`}
        column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="Número de Recepción">{lot.identifier ?? ''}</Descriptions.Item>
        <Descriptions.Item label="Productor">{lot.producer?.name ?? ''}</Descriptions.Item>
        <Descriptions.Item label="Fecha Recepción">{lot.reception_date ?? ''}</Descriptions.Item>
        <Descriptions.Item label="Variedad">{lot.variety ?? ''}</Descriptions.Item>
        <Descriptions.Item label="Planta Procesos">{lot.plant?.name ?? ''}</Descriptions.Item>
        <Descriptions.Item label="Kilogramos Ingresados">{lot.total_wet_kgs ?? ''}</Descriptions.Item>
        <Descriptions.Item label="Tipo Envase">{lot.packing_type ?? ''}</Descriptions.Item>
        <Descriptions.Item label="Cantidad Envases">{lot.amount_of_packages ?? ''}</Descriptions.Item>
        <Descriptions.Item label="% Humedad Ingreso">{lot.humidity_percentage_in ?? ''}</Descriptions.Item>
        <Descriptions.Item label="% Humedad Salida">{lot.humidity_percentage_out ?? ''}</Descriptions.Item>
        <Descriptions.Item label="Kilogramos Secos">{lot.total_dried_kgs ?? ''}</Descriptions.Item>
        <Descriptions.Item label="Rendimiento Secado">{lot.drying_process_efficiency ?? ''}</Descriptions.Item>
        <Descriptions.Item label="Fecha Comienzo Secado">{lot.drying_started_at ?? ''}</Descriptions.Item>
        <Descriptions.Item label="Fecha Término Secado">{lot.drying_finished_at ?? ''}</Descriptions.Item>
        <Descriptions.Item label="Tiempo Total Secado">
          {lot.drying_total_time?.replace('days', 'Días') ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="Guía de Despacho">{lot.guide_number ?? ''}</Descriptions.Item>
        <Descriptions.Item label="Transportista">{lot.carrier_name ?? ''}</Descriptions.Item>
        <Descriptions.Item label="Patente Camión">{lot.truck ?? ''}</Descriptions.Item>
        <Descriptions.Item label="Patente Carro">{lot.license_plate ?? ''}</Descriptions.Item>
      </Descriptions>

      <div className={styles.qualityButtons}>
        {lot.quality_analysis ? (
          <>
            <Button className={styles.button} type="primary" onClick={() => handleGetAnalysis(lot.quality_analysis)}>
              Descargar Informe Calidad
            </Button>
            <Button className={styles.button} type="primary" onClick={handleUploadAnalysis}>
              Cambiar Informe Calidad
            </Button>
            <Button className={styles.button} type="primary" onClick={handleDeleteAnalysis}>
              Eliminar Informe Calidad
            </Button>
          </>
        ) : (
          <Button className={styles.button} type="primary" onClick={handleUploadAnalysis}>
            Cargar Informe Calidad
          </Button>
        )}
      </div>
      <div className={styles.actionButtons}>
        <Button className={styles.button} type="primary" onClick={handleEdit}>
          Editar
        </Button>
        <Button type="primary" onClick={handleDelete}>
          Eliminar
        </Button>
      </div>
    </Modal>
  );
};

export default AdministrateLotModal;
