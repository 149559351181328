import { Button, Descriptions, Modal } from 'antd';
import { IPlantInfo } from '../../../../models/plant';
import styles from './AdministratePlantModal.module.scss';

interface IAdministrateLotModalProps {
  visible: boolean;
  setVisible: Function;
  handleEdit: React.MouseEventHandler;
  plant: IPlantInfo;
  handleDelete: React.MouseEventHandler;
}

const AdministratePlantModal: React.FC<IAdministrateLotModalProps> = ({
  visible,
  setVisible,
  handleEdit,
  plant,
  handleDelete
}) => {
  const handleClose = () => {
    setVisible(false);
  };
  return (
    <Modal closable visible={visible} onOk={handleClose} onCancel={handleClose}>
      <Descriptions
        className={styles.description}
        layout={'horizontal'}
        title={`Información de la Planta`}
        column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="Planta">{plant.name}</Descriptions.Item>
        <Descriptions.Item label="Correo">{plant.email}</Descriptions.Item>
        <Descriptions.Item label="Telefono">{plant.phone}</Descriptions.Item>
        <Descriptions.Item label="Direccion">{plant.address}</Descriptions.Item>
      </Descriptions>

      <div className={styles.buttonContainer}>
        <Button className={styles.button} type="primary" onClick={handleDelete}>
          Eliminar
        </Button>
        <Button className={styles.button} type="primary" onClick={handleEdit}>
          Editar
        </Button>
      </div>
    </Modal>
  );
};

export default AdministratePlantModal;
