import { http } from '../api/http.provider';
import { IPlantInfo } from '../models/plant';

interface IPlantsResponse {
  plants: Array<IPlantInfo>;
}

export const getPlants = (): Promise<IPlantsResponse> => {
  let url = 'api/v1/plants/all';
  return http.get(url, { withCredentials: true }).then(({ data }) => data);
};
