import Logo from './logo.png';
import { Avatar } from '../../../../../../components/index';
import styles from './NavBrand.module.scss';
import { AvatarSizes } from '../../../../../../components/avatar/resources/Avatar.enum';

interface INavBrandProps {
  className: string;
}

const NavBrand: React.FC<INavBrandProps> = (props: INavBrandProps) => {
  return (
    <div className={props.className}>
      <Avatar className={styles.logo} src={Logo} sizes={AvatarSizes.XExtraLarge} />
    </div>
  );
};

export default NavBrand;
