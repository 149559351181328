import { http } from '../../../api/http.provider';
import { IGetPlantsResponse, IDeletePlantResponse, IPlantResponse } from '../models/response';

export const getPlants = (): Promise<IGetPlantsResponse> => {
  let url = 'api/v1/plants/all';
  return http.get(url, { withCredentials: true }).then(({ data }) => data);
};

export const deletePlant = (plant_id: number): Promise<IDeletePlantResponse> => {
  let url = `api/v1/plants/delete/${plant_id}`;
  return http.get(url, { withCredentials: true }).then(({ data }) => data);
};

export const editPlant = (values: any): Promise<IPlantResponse> => {
  let url = 'api/v1/plants/update';
  return http.post(url, values, { withCredentials: true }).then(({ data }) => data);
};
