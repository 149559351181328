import { Button, Form, Input, InputNumber, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { IPlantInfo } from '../../../../models/plant';
import styles from './EditPlantModal.module.scss';

interface IEditPlantModalProps {
  visible: boolean;
  setVisible: Function;
  plant: IPlantInfo;
  setChanges: Function;
  changes: boolean;
  handleEdit: (values: any) => void;
}

const EditPlantModal: React.FC<IEditPlantModalProps> = ({
  visible,
  setVisible,
  plant,
  setChanges,
  changes,
  handleEdit
}) => {
  const [form] = useForm();

  const layout = {
    labelCol: {
      span: 8
    },
    wrapperCol: {
      span: 16
    }
  };

  const handleClose = () => {
    form.resetFields();
    setVisible(false);
  };
  return (
    <Modal title="Editar Planta" visible={visible} onCancel={handleClose} footer={null}>
      <Form {...layout} form={form} onFinish={handleEdit}>
        <Form.Item
          label="Nombre"
          name="name"
          initialValue={plant.name}
          rules={[
            {
              required: true,
              message: 'Ingresa el nombre de la planta'
            }
          ]}
        >
          <Input type="text"></Input>
        </Form.Item>

        <Form.Item
          label="Dirección"
          name="address"
          initialValue={plant.address}
          rules={[
            {
              required: true,
              message: 'Ingresa la dirección!'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          initialValue={plant.email}
          rules={[
            {
              required: true,
              message: 'Ingresa el email de la planta!',
              type: 'email'
            }
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          label="Telefono"
          name="phone"
          rules={[
            {
              required: true,
              message: 'Ingresa el telefono de la planta!'
            }
          ]}
          initialValue={plant.phone}
        >
          <InputNumber max={999999999} min={100000} controls={false} />
        </Form.Item>

        <Form.Item className={styles.formButtons}>
          <Button className={styles.formCancel} onClick={handleClose}>
            Cancelar
          </Button>
          <Button className={styles.formCreate} type="primary" htmlType="submit">
            Editar
          </Button>
        </Form.Item>
        <Form.Item noStyle initialValue={plant.id} name="id"></Form.Item>
      </Form>
    </Modal>
  );
};

export default EditPlantModal;
