enum AvatarVariant {
  Circle = 'circle',
  Square = 'square',
  Icon = 'icon'
}

enum AvatarSizes {
  ExtraSmall = 'xSmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  ExtraLarge = 'xLarge',
  XExtraLarge = 'xxLarge'
}

export { AvatarSizes, AvatarVariant };
