import { IUser } from '../models/user';
import { decodedJwt } from './jwt.helper';

export const getCurrentUser = (): IUser | null => {
  return decodedJwt();
};

export const isAdmin = (): boolean => {
  return decodedJwt()?.role === 1 || decodedJwt()?.role === 2;
};

export const isSuperAdmin = (): boolean => {
  return decodedJwt()?.role === 2;
};

export const isQualityDepartment = (): boolean => {
  return decodedJwt()?.username === 'alejandra' || decodedJwt()?.username === 'marco' || decodedJwt()?.role === 2;
};
