import { http } from '../../../api/http.provider';
import { ILoginResponse } from '../models/response';

export const postCredentials = (username: string, password: string, ip: string): Promise<ILoginResponse> => {
  let url = 'api/v1/auth/login';
  return http.post(url, { username: username, password: password, ip: ip }).then(({ data }) => data);
};

export const getIPAddress = (): Promise<any> => {
  let url = 'https://geolocation-db.com/json/';
  return fetch(url).then((data) => data.json());
  // return http.get(url).then(({data}) => data);
  // fetch('https://ipapi.co/json/');
};
