import { Button, Divider, Form, message, Result, Upload } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { RcFile } from 'antd/lib/upload';
import { useState } from 'react';
import { AiOutlineUpload } from 'react-icons/ai';
import { globalService } from '../../services/global.service';
import styles from './GenerateAnalysisPDF.module.scss';
import { NonGeneratedPDF } from './models/response';
import { uploadReceptionFile } from './services/analysis.service';

const GenerateAnalysisPDF: React.FC = () => {
  const [showNonGenerated, setShowNonGenerated] = useState(false);
  const formData = new FormData();

  const beforeUpload = (file: RcFile, fileList: RcFile[]) => {
    formData.append('files', file);
    return false;
  };

  const handleUpload = (values: any) => {
    uploadReceptionFile(formData)
      .then((res) => {
        if (res.non_generated) {
          setShowNonGenerated(true);
          message.success('Analisis Cargados Exitosamente!');
          return;
        }
        message.error('Error al Generar Informes!');
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al Comunicarse con el Servidor!');
      });
  };

  return (
    <div className={styles.container}>
      <Header className={styles.header}>
        <h3 style={{ fontWeight: 600 }}>Generar Informes PDF</h3>
      </Header>
      <Divider className={styles.divider} />
      <Form name="file_upload" onFinish={handleUpload} layout={'inline'}>
        <Form.Item
          name="files"
          rules={[
            {
              required: true,
              message: 'Ingresa un archivo!'
            }
          ]}
        >
          <Upload multiple={false} beforeUpload={beforeUpload}>
            <Button icon={<AiOutlineUpload />}>Seleccionar Archivo</Button>
          </Upload>
        </Form.Item>
        <Form.Item className={styles.formButtons}>
          <Button type="primary" htmlType="submit">
            Cargar
          </Button>
        </Form.Item>
      </Form>

      {showNonGenerated ? (
        <Result
          className={styles.successMessage}
          status="success"
          title="Los Informes Han Sido Procesados Con Exito!"
          subTitle="Con el boton puedes descargar el archivo que contiene los lotes que contienen errores y no fueron generados!"
          extra={[
            <Button type="primary" key="ceased" href={`${globalService.apiUrl}/api/v1/pdf/analysis-result`}>
              Lotes No Generados
            </Button>
          ]}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default GenerateAnalysisPDF;
