import { Button, message, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { TableSearch } from '../../components';
import { IPlantInfo } from '../../models/plant';
import CreatePlantContainer from '../create-plant-container/CreatePlantContainer';
import { deletePlant, editPlant, getPlants } from './services/plant.service';
import styles from './PlantsContainer.module.scss';
import AdministratePlantModal from './components/AdministratePlantModal/AdministratePlantModal';
import EditPlantModal from './components/EditPlantModal/EditPlantModal';
import { AiOutlinePlus, AiOutlineUserAdd } from 'react-icons/ai';
import { FaWarehouse } from 'react-icons/fa';

const PlantContainer: React.FC = () => {
  const [showAdministrateModal, setShowAdministrateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [plants, setPlants] = useState<IPlantInfo[]>([]);
  const [filteredPlants, setFilteredPlants] = useState<IPlantInfo[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPlantInfo, setSelectedPlantInfo] = useState({} as IPlantInfo);

  useEffect(() => {
    getPlants()
      .then((res) => {
        if (res.plants) {
          setPlants(res.plants);
          setFilteredPlants(res.plants);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al Comunicarse con el Servidor!');
      });
  }, [changesMade]);

  const columns = [
    {
      title: 'Planta',
      dataIndex: 'name'
    },
    {
      title: 'Correo',
      dataIndex: 'email'
    },
    {
      title: 'Dirección',
      dataIndex: 'address'
    },
    {
      title: 'Telefóno',
      dataIndex: 'phone'
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'actions',
      width: 'auto',
      render: (id: number) => (
        <Button key={id} onClick={() => handleAdministrate(id)} type="primary">
          Administrar
        </Button>
      )
    }
  ];

  const filterByNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = e.target.value;
    const filteredData = plants.filter((entry) => entry.name.includes(currValue));
    setFilteredPlants(filteredData);
  };

  const handleAdministrate = (id: number) => {
    plants.map((value, index) => {
      if (value.id === id) {
        setSelectedPlantInfo(value);
        setShowAdministrateModal(true);
      }
      return value;
    });
  };
  const onDelete = () => {
    setShowAdministrateModal(false);
    setShowDeleteModal(true);
  };
  const handleDelete = () => {
    deletePlant(selectedPlantInfo.id)
      .then((res) => {
        if (res.success) {
          message.success('Exito Eliminando la Planta!');
          setShowDeleteModal(false);
          setChangesMade(!changesMade);
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al Comunicarse con el Servidor!');
      });
  };

  const openEdit = () => {
    setShowAdministrateModal(false);
    setShowEditModal(true);
  };

  const handleEdit = (values: any) => {
    editPlant(values)
      .then((res) => {
        if (res.plant) {
          message.success('Se ha editado con exito!');
          setShowEditModal(false);
          setChangesMade(!changesMade);
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al Comunicarse con el Servidor!');
      });
  };

  const showCreatePlantModal = () => {
    setShowModal(true);
    setShowAdministrateModal(false);
    setShowEditModal(false);
  };

  return (
    <div className={styles.container}>
      <TableSearch
        onRow={(record: IPlantInfo, _: number) => {
          return {
            onClick: (e: MouseEvent) => {
              handleAdministrate(record.id);
            }
          };
        }}
        className={styles.table}
        columns={columns}
        data={filteredPlants}
        title="Plantas"
        filter={filterByNameInput}
      >
        <Button onClick={showCreatePlantModal} className={styles.add} type="primary">
          <AiOutlinePlus className={styles.icon} />
        </Button>
      </TableSearch>
      <CreatePlantContainer
        visible={showModal}
        setVisible={setShowModal}
        changes={changesMade}
        setChanges={setChangesMade}
      />
      <AdministratePlantModal
        visible={showAdministrateModal}
        setVisible={setShowAdministrateModal}
        handleEdit={openEdit}
        handleDelete={onDelete}
        plant={selectedPlantInfo}
      />
      <EditPlantModal
        visible={showEditModal}
        setVisible={setShowEditModal}
        plant={selectedPlantInfo}
        handleEdit={handleEdit}
        setChanges={setChangesMade}
        changes={changesMade}
      />
      <Modal
        title="Eliminar Lote"
        visible={showDeleteModal}
        okText="Eliminar"
        onOk={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
      >
        <h4>Esta seguro que desea eliminar la planta?</h4>
      </Modal>
    </div>
  );
};

export default PlantContainer;
