import axios from 'axios';
import { clearSessionStorage } from '../helpers/cookies.helper';
// import { getCookie, setCookie } from "../helpers/cookies.helper";
import { globalService } from '../services/global.service';

const http = axios.create({
  baseURL: globalService.apiUrl
});

http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

http.interceptors.response.use(undefined, (err) => {
  if (err.response.status === 419) {
    clearSessionStorage();
    window.location.href = '/login';
  }
  return Promise.reject(err);
});

export { http };
