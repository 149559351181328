import { http } from '../../../api/http.provider';
import { IGenerateAnalysisPDFResponse } from '../models/response';

export const uploadReceptionFile = (file: any): Promise<IGenerateAnalysisPDFResponse> => {
  let url = 'api/v1/pdf/analysis';
  return http
    .post(url, file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    })
    .then(({ data }) => data);
};
