import React, { useEffect, useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { Modal } from 'antd';
import { clearSessionStorage } from '../../helpers/cookies.helper';
import { Navigate, useNavigate } from 'react-router-dom';

const IdleTimerContainer: React.FC = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const sessionTimeoutRef = useRef<any>(null);
  const navigate = useNavigate();

  const logOut = () => {
    clearSessionStorage();
    clearTimeout(sessionTimeoutRef.current);
    navigate('/login', { replace: true });
  };

  const stayLogged = () => {
    setShowModal(false);
    clearTimeout(sessionTimeoutRef.current);
  };

  const handleOnIdle = (event: any) => {
    setShowModal(true);
    sessionTimeoutRef.current = setTimeout(logOut, 5000);
  };

  return (
    <IdleTimer timeout={300 * 1000} crossTab={true} onIdle={handleOnIdle}>
      <Modal
        closable
        title="Usuario Inactivo"
        visible={showModal}
        okText={'Continuar'}
        onOk={stayLogged}
        cancelText={'Salir'}
        onCancel={logOut}
      >
        <h3> La sesión se acabara por inactividad en 10 segundos</h3>
      </Modal>
      {children}
    </IdleTimer>
  );
};

export default IdleTimerContainer;
