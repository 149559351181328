import { Button, Form, Input, InputNumber, message, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import { ILot } from '../../../../models/lot';
import { IPlantInfo } from '../../../../models/plant';
import { IUserInfo } from '../../../../models/user';
import { getPlants } from '../../../../services/getPlants.service';
import { getUsers } from '../../../../services/getUsers.service';
import styles from './EditLotModal.module.scss';

interface IEditLotModalProps {
  visible: boolean;
  setVisible: Function;
  lot: ILot;
  onEdit: (values: any) => void;
  setSelectedLot: Function;
}

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 8
    }
  },
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 16
    }
  }
};

const EditLotModal: React.FC<IEditLotModalProps> = ({ visible, setVisible, onEdit, lot, setSelectedLot }) => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState<Array<IUserInfo>>([]);
  const [plants, setPlants] = useState<Array<IPlantInfo>>([]);

  useEffect(() => {
    getUsers()
      .then((res) => {
        if (res.users) {
          setUsers(res.users);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getPlants()
      .then((res) => {
        if (res.plants) {
          setPlants(res.plants);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onFinish = (values: any) => {
    onEdit(values);
    setSelectedLot(undefined);
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    Object.keys(changedValues).forEach((key: any) => {
      if (changedValues[key] === null) {
        form.setFieldsValue({ [key]: undefined });
      }
      return changedValues;
    });

    return allValues;
  };
  const handleClose = () => {
    setVisible(false);
    setSelectedLot(undefined);
  };
  return (
    <Modal width={'60%'} title={'Editar lote'} visible={visible} closable onCancel={handleClose} footer={null}>
      <div className={styles.formContainer}>
        <Form
          {...formItemLayout}
          form={form}
          onFinish={onFinish}
          labelWrap
          scrollToFirstError
          onValuesChange={onValuesChange}
          className={styles.form}
        >
          <Form.Item name="id" noStyle initialValue={lot.id}></Form.Item>

          <Form.Item
            name="identifier"
            label="Número de Recepción"
            tooltip="Identificador del lote"
            initialValue={lot.identifier}
            rules={[
              {
                required: true,
                message: 'Ingresa el nombre de usuario!'
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="producer_id"
            label="Productor"
            initialValue={lot.producer.id}
            rules={[
              {
                required: true,
                message: 'Ingresa el usuario productor del lot?e'
              }
            ]}
          >
            <Select
              showSearch
              placeholder="Selecciona un productor"
              optionFilterProp="children"
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {users.map((user, index) => {
                return (
                  <Option key={user.id} value={user.id}>
                    {user.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="reception_date"
            label="Fecha de Recepción"
            initialValue={lot.reception_date ?? ''}
            rules={[
              {
                required: true,
                message: 'Debe ingresar la fecha con formato DD/MM/AAAA HH:mm!',
                pattern: new RegExp(
                  /^([1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/\d\d\d\d [012]{0,1}[0-9]:[0-5][0-9]$/
                )
              }
            ]}
          >
            <Input placeholder="DD/MM/AAAA HH:mm" />
          </Form.Item>
          <Form.Item
            name="plant_id"
            label="Planta de Procesos"
            initialValue={lot.plant.id}
            rules={[
              {
                required: true,
                message: 'Ingresa la planta donde ingreso el lote'
              }
            ]}
          >
            <Select
              showSearch
              placeholder="Selecciona un productor"
              optionFilterProp="children"
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {plants.map((plant, index) => {
                return (
                  <Option key={plant.id} value={plant.id}>
                    {plant.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="variety"
            label="Variedad"
            initialValue={lot.variety}
            rules={[
              {
                required: true,
                message: 'Ingresa la variedad de la fruta del lote'
              }
            ]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            name="total_wet_kgs"
            label="Kilogramos Humedos Ingresados"
            tooltip="Utiliza el punto para numeros decimales 3.14"
            initialValue={lot.total_wet_kgs}
            rules={[
              {
                required: true,
                message: 'Ingresa la cantidad de kilos recibidos'
              }
            ]}
          >
            <InputNumber min={0} max={999999999} controls={false}></InputNumber>
          </Form.Item>
          <Form.Item
            name="packing_type"
            label="Tipo Envase"
            initialValue={lot.packing_type}
            rules={[
              {
                required: true,
                message: 'Ingresa el tipo de envase'
              }
            ]}
          >
            <Select placeholder="Selecciona el tipo de envase" allowClear defaultValue={lot.packing_type}>
              <Option value="Maxisaco">Maxisacos</Option>
              <Option value="Bins">Bins</Option>
              <Option value="Bateas">Bateas</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="amount_of_packages"
            label="Cantidad de Envases"
            initialValue={lot.amount_of_packages}
            rules={[
              {
                required: true,
                message: 'Ingresa la cantidad de envases'
              }
            ]}
          >
            <InputNumber min={0} max={999999999} controls={false}></InputNumber>
          </Form.Item>

          <Form.Item
            name="guide_number"
            label="Guía de Despacho"
            initialValue={lot.guide_number}
            rules={[
              {
                required: true,
                message: 'Ingresa el numero de la guia de despacho'
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="humidity_percentage_in"
            label="Porcentaje de Humedad Ingreso"
            initialValue={lot?.humidity_percentage_in ?? undefined}
          >
            <InputNumber min={0} max={100} controls={false} decimalSeparator={','} />
          </Form.Item>
          <Form.Item
            name="humidity_percentage_out"
            label="Porcentaje de Humedad Salida"
            initialValue={lot?.humidity_percentage_out ?? undefined}
          >
            <InputNumber min={0} max={100} controls={false} decimalSeparator={','} />
          </Form.Item>
          <Form.Item
            name="total_dried_kgs"
            label="Kilogramos Secos Salida"
            tooltip="Utiliza el punto para numeros decimales 3.14"
            initialValue={lot.total_dried_kgs ?? 0}
          >
            <InputNumber min={0} max={999999999} controls={false}></InputNumber>
          </Form.Item>
          <Form.Item
            name="drying_process_efficiency:"
            label="Rendimiento Secado"
            initialValue={lot.drying_process_efficiency ?? undefined}
          >
            <InputNumber min={0} max={100} controls={false} decimalSeparator={','} />
          </Form.Item>
          <Form.Item name="carrier_name" label="Nombre Transportista" initialValue={lot.carrier_name ?? ''}>
            <Input />
          </Form.Item>
          <Form.Item name="license_plate" label="Patente Camión" initialValue={lot.license_plate ?? ''}>
            <Input />
          </Form.Item>
          <Form.Item name="truck" label="Patente Carro" initialValue={lot.truck ?? ''}>
            <Input />
          </Form.Item>
          <Form.Item
            name="drying_started_at"
            label="Fecha Comienzo Secado"
            initialValue={lot.drying_started_at ?? undefined}
            rules={[
              {
                message: 'Debe ingresar la fecha con formato DD/MM/AAAA HH:mm!',
                pattern: new RegExp(
                  /^([1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/\d\d\d\d [012]{0,1}[0-9]:[0-5][0-9]$/
                )
              }
            ]}
          >
            <Input placeholder="DD/MM/AAAA HH:mm" />
          </Form.Item>
          <Form.Item
            name="drying_finished_at"
            label="Fecha de Término Secado"
            initialValue={lot.drying_finished_at ?? undefined}
            rules={[
              {
                message: 'Debe ingresar la fecha con formato DD/MM/AAAA HH:mm!',
                pattern: new RegExp(
                  /^([1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/\d\d\d\d [012]{0,1}[0-9]:[0-5][0-9]$/
                )
              }
            ]}
          >
            <Input placeholder="DD/MM/AAAA HH:mm" />
          </Form.Item>
          <Form.Item className={styles.formButtons}>
            <Button className={styles.formCancel} onClick={handleClose}>
              Cancelar
            </Button>
            <Button className={styles.formEdit} type="primary" htmlType="submit">
              Editar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditLotModal;
