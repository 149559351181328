import { Button, Form, Input, message, Modal } from 'antd';
import { changePassword } from '../../services/changePassword.service';
import styles from './ChangePasswordModal.module.scss';

interface IChangePasswordModalProps {
  visible: boolean;
  setVisible: Function;
  userId: number;
}

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};

const ChangePasswordModal: React.FC<IChangePasswordModalProps> = (props: IChangePasswordModalProps) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    props.setVisible(false);
  };
  const handleChangePassword = (values: any) => {
    changePassword(values)
      .then((res) => {
        if (res.success) {
          message.success('Contraseña actualizada exitosamente!');
          props.setVisible(false);
          return;
        }
        props.setVisible(false);
        message.error('Error al actualizar contraseña');
      })
      .catch((err) => {
        if (err.response) {
          props.setVisible(false);
          message.error(err.data);
          return;
        }
        props.setVisible(false);
        message.error('Error al comunicarse con el servidor!');
      });
  };
  return (
    <Modal visible={props.visible} title="Cambiar Contraseña" onCancel={handleCancel} footer={null}>
      <Form {...layout} form={form} onFinish={handleChangePassword}>
        <Form.Item name="id" label="id" noStyle initialValue={props.userId}></Form.Item>
        <Form.Item
          name="password"
          label="Contraseña"
          rules={[
            {
              required: true,
              message: 'Ingresa una contraseña!'
            }
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirma Contraseña"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Confirma tu contraseña!'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('Ambas contraseñas no coinciden!'));
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item className={styles.formButtons}>
          <Button className={styles.formCancel} onClick={handleCancel}>
            Cancelar
          </Button>
          <Button className={styles.formChangePassword} type="primary" htmlType="submit">
            Cambiar Contraseña
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
