import jwtDecode from 'jwt-decode';
import { getCookie, setCookie } from './cookies.helper';

import { IUser } from '../models/user';
import { globalService } from '../services/global.service';

var jwt = require('jsonwebtoken');

export const jwtToken = (): string | undefined => {
  return getCookie('nds-jwt');
};

export const decodedJwt = (token?: string): IUser | null => {
  const tk = token ?? jwtToken();
  if (tk) {
    return jwtDecode(tk);
  }
  return null;
};

export const setJwtToken = (token: string, domain?: string) => {
  setCookie('nds-jwt', token, domain);
};
