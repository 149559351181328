import React, { FunctionComponent } from 'react';
const styles = require('./AvatarDescription.module.scss');

interface IAvatarDescription {
  name?: string;
  rol?: string;
  identifier?: string;
}

const AvatarDescription: FunctionComponent<IAvatarDescription> = ({ name, rol, identifier }) => {
  return (
    <div className={styles.description}>
      <h6 className={styles.name}>{name}</h6>
      <p className={styles.rol}>{rol}</p>
      <p className={styles.rol}>{identifier}</p>
    </div>
  );
};

export default AvatarDescription;
