import { Button, Form, Input, InputNumber, message, Modal, Select } from 'antd';
import { Option } from 'antd/lib/mentions';
import { isSuperAdmin } from '../../../../helpers/user.helper';
import { IUserInfo } from '../../../../models/user';
import { editUser } from '../../services/users.service';
import styles from './EditUserModal.module.scss';

interface IEditUserModalProps {
  visible: boolean;
  setVisible: Function;
  user: IUserInfo;
  setChanges: Function;
  changes: boolean;
}

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};

const EditUserModal: React.FC<IEditUserModalProps> = (props: IEditUserModalProps) => {
  const [form] = Form.useForm();

  const handleEdit = (values: any) => {
    if (values.phone == null) {
      values.phone = undefined;
    }
    if (values.address == null) {
      values.address = undefined;
    }

    editUser(values)
      .then((res) => {
        if (res.user) {
          message.success('Usuario editado con exito!');
          props.setVisible(false);
          props.setChanges(!props.changes);
          form.resetFields();
          return;
        }
        message.error('Usuario no ha sido editado con exito!');
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error Comunicándose con el Servidor!');
      });
    props.setChanges(!props.changes);
  };

  const handleClose = () => {
    form.resetFields();
    props.setVisible(false);
  };

  const onRoleChange = (value: string) => {
    switch (value) {
      case 'Productor':
        form.setFieldsValue({
          role: 0
        });
        return;

      case 'Administrador':
        form.setFieldsValue({
          role: 1
        });
        return;
    }
  };
  const parseRole = () => {
    switch (props.user.role) {
      case 1:
        return 'Administrador';

      case 2:
        return 'Superadministrador';

      default:
        return 'Productor';
    }
  };

  return (
    <Modal title="Editar Usuario" visible={props.visible} footer={null} onCancel={handleClose}>
      <Form {...layout} form={form} onFinish={handleEdit}>
        <Form.Item
          label="Usuario"
          name="username"
          rules={[
            {
              required: true,
              message: 'Ingresa el nombre de usuario!'
            }
          ]}
          initialValue={props.user.username}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nombre"
          name="name"
          rules={[
            {
              required: true,
              message: 'Ingresa el nombre!'
            }
          ]}
          initialValue={props.user.name}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Rut"
          name="rut"
          rules={[
            {
              required: true,
              message: 'Ingresa el rut del usuario!'
            }
          ]}
          initialValue={props.user.rut}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Ingresa el email del usuario!',
              type: 'email'
            }
          ]}
          initialValue={props.user.email}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Dirección" name="address" initialValue={props.user.address}>
          <Input />
        </Form.Item>
        <Form.Item label="Teléfono" name="phone" initialValue={props.user.phone}>
          <InputNumber controls={false} />
        </Form.Item>

        {isSuperAdmin() ? (
          <>
            <Form.Item
              label="Rol"
              name="role_change"
              rules={[
                {
                  required: true,
                  message: 'Ingresa el rol del usuario'
                }
              ]}
              initialValue={parseRole()}
            >
              <Select
                placeholder="Selecciona el rol del usuario"
                onChange={onRoleChange}
                allowClear
                defaultValue={parseRole()}
              >
                <Option value="Productor">Productor</Option>
                <Option value="Administrador">Administrador</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Rol" name="role" noStyle initialValue={props.user.role}></Form.Item>
          </>
        ) : (
          <Form.Item label="Rol" name="role" noStyle initialValue={props.user.role}></Form.Item>
        )}
        <Form.Item label="ID" name="id" noStyle initialValue={props.user.id}></Form.Item>

        <Form.Item className={styles.formButtons}>
          <Button className={styles.formCancel} onClick={handleClose}>
            Cancelar
          </Button>
          <Button className={styles.formEdit} type="primary" htmlType="submit">
            Editar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditUserModal;
