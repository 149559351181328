class GlobalService {
  get apiUrl() {
    return process.env.REACT_APP_API_URL;
  }

  get port() {
    return process.env.PORT;
  }

  get environment() {
    return process.env.NODE_ENV;
  }
  get appUrl() {
    return process.env.REACT_APP_APP_URL;
  }
  get jwtKey() {
    return process.env.JWT_PASSWORD;
  }
}

const globalService = new GlobalService();
export { globalService };
