import { Button, Form, Input, List, message, Modal, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { AiOutlineUpload } from 'react-icons/ai';
import { IFile } from '../../../../models/file';
import { createFolder, deleteFiles, uploadFiles } from '../../services/files.service';
import styles from './ActionModals.module.scss';

interface IActionModals {
  showUpload: boolean;
  setShowUpload: any;
  showDelete: boolean;
  setShowDelete: any;
  showCreate: boolean;
  setShowCreate: any;
  selectedFiles: Array<IFile>;
  actionPerformed: boolean;
  setActionPerformed: Function;
  setSelectedFiles: Function;
  path: string;
  user: string;
}

const ActionModals: React.FC<IActionModals> = (props: IActionModals) => {
  const [form] = Form.useForm();
  const formData = new FormData();

  const handleUpload = (values: any) => {
    uploadFiles(formData, props.path)
      .then((res) => {
        if (res.success) {
          props.setShowUpload(false);
          props.setActionPerformed(!props.actionPerformed);
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al Comunicarse con el Servidor!');
      });
  };

  const selectedFilePaths = () => {
    var filePaths: string[] = [];
    props.selectedFiles.map((file) => {
      return filePaths.push(file.path);
    });
    return filePaths;
  };

  const handleDelete = () => {
    deleteFiles(selectedFilePaths(), props.user)
      .then((res) => {
        if (res.success) {
          props.setShowDelete(false);
          props.setActionPerformed(!props.actionPerformed);
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al Comunicarse con el Servidor!');
      });
  };

  const handleCreate = (values: any) => {
    createFolder(props.path + '/' + values.foldername, props.user)
      .then((res) => {
        if (res.success) {
          props.setShowCreate(false);
          form.resetFields();
          props.setActionPerformed(!props.actionPerformed);
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al Comunicarse con el Servidor');
      });
  };

  const handleClose = () => {
    props.setShowUpload(false);
    props.setShowCreate(false);
    props.setShowDelete(false);
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    const specialCharRegex = new RegExp('[a-zA-Z0-9]');
    const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (!specialCharRegex.test(pressedKey)) {
      e.preventDefault();
      return false;
    }
  };

  const beforeUpload = (file: RcFile, fileList: RcFile[]) => {
    formData.append('files', file);
    return false;
  };

  const removeFromDeleted = (filename: string) => {
    const updatedFiles = props.selectedFiles.filter((file) => {
      return file.filename !== filename;
    });
    console.table(updatedFiles);
    props.setSelectedFiles(updatedFiles);
  };

  return (
    <>
      <Modal
        visible={props.showUpload}
        title={'Cargar Archivo'}
        className={styles.uploadModal}
        footer={null}
        onCancel={handleClose}
      >
        <Form name="file_upload" onFinish={handleUpload}>
          <Form.Item
            name="files"
            rules={[
              {
                required: true,
                message: 'Ingresa un archivo!'
              }
            ]}
          >
            <Upload multiple={false} beforeUpload={beforeUpload}>
              <Button icon={<AiOutlineUpload />}>Seleccionar Archivo</Button>
            </Upload>
          </Form.Item>
          <Form.Item className={styles.formButtons}>
            <Button className={styles.formCancel} onClick={handleClose}>
              Cancelar
            </Button>
            <Button type="primary" htmlType="submit">
              Cargar
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        footer={null}
        okButtonProps={{ style: { display: 'none' } }}
        visible={props.showCreate}
        title={'Crear Carpeta'}
        className={styles.createFolderModal}
        onOk={handleCreate}
        okText="Crear"
        onCancel={handleClose}
        cancelText="Cancelar"
      >
        <Form form={form} name="createFolderForm" onFinish={handleCreate}>
          <Form.Item
            label="Nombre"
            name="foldername"
            rules={[
              {
                required: true,
                message: 'Ingresa el nombre de la carpeta!'
              }
            ]}
          >
            <Input title="Nombre Carpeta" type="text" onKeyPress={onKeyPress}></Input>
          </Form.Item>
          <Form.Item className={styles.formButtons}>
            <Button className={styles.formCancel} onClick={handleClose}>
              Cancelar
            </Button>
            <Button type="primary" htmlType="submit">
              Crear
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        visible={props.showDelete}
        title={'Eliminar Archivos'}
        className={styles.deteleModal}
        onOk={handleDelete}
        okText={'Eliminar'}
        onCancel={handleClose}
        cancelText={'Cancelar'}
      >
        <h4>Seguro que desea eliminar los archivos?</h4>
        <List size="small">
          {props.selectedFiles.map((file) => {
            return (
              <List.Item actions={[<Button onClick={() => removeFromDeleted(file.filename)}>No Eliminar</Button>]}>
                {file.filename}
              </List.Item>
            );
          })}
        </List>
      </Modal>
    </>
  );
};

export default ActionModals;
