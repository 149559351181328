import { Button, Form, Modal, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { AiOutlineUpload } from 'react-icons/ai';
import { ILot } from '../../../../models/lot';
import styles from './UploadAnalysisModal.module.scss';

interface UploadAnalysisModalProps {
  visible: boolean;
  setVisible: Function;
  lot: ILot;
  handleUpload: Function;
}

const UploadAnalysisModal: React.FC<UploadAnalysisModalProps> = ({ visible, setVisible, lot, handleUpload }) => {
  const fd = new FormData();

  const handleClose = () => setVisible(false);

  const beforeUpload = (file: RcFile, fileList: RcFile[]) => {
    fd.append('files', file);
    return false;
  };

  const onFinish = (values: any) => {
    return handleUpload(fd);
  };

  return (
    <Modal visible={visible} onCancel={handleClose} footer={null}>
      <Form onFinish={onFinish}>
        <Form.Item
          name="files"
          rules={[
            {
              required: true,
              message: 'Ingresa un archivo!'
            }
          ]}
        >
          <Upload multiple={false} beforeUpload={beforeUpload}>
            <Button icon={<AiOutlineUpload />}>Seleccionar Archivo</Button>
          </Upload>
        </Form.Item>
        <Form.Item className={styles.formButtons}>
          <Button className={styles.formCancel} onClick={handleClose}>
            Cancelar
          </Button>
          <Button type="primary" htmlType="submit">
            Cargar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UploadAnalysisModal;
