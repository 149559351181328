import { Button, message, Modal, Tabs, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { AiOutlineDownload, AiOutlineFilePdf, AiOutlinePlus } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { TableSearch } from '../../components';
import { ILot } from '../../models/lot';
import { IPlantInfo } from '../../models/plant';
import { IUser } from '../../models/user';
import { getFile } from '../../services/files.service';
import { globalService } from '../../services/global.service';
import AdministrateLotModal from './components/administrate-lot-modal/AdministrateLotModal';
import EditLotModal from './components/edit-lot-modal/EditLotModal';
import UploadAnalysisModal from './components/upload-analysis-modal/UploadAnalysisModal';
import { createConsolidados, deleteAnalysis, deleteLot, editLot, getLots, linkAnalysis } from './services/lots.service';
import styles from './ViewLotsContainer.module.scss';

const ViewLotsContainer: React.FC = () => {
  const columns = [
    {
      title: 'Lote',
      dataIndex: 'identifier',
      fixed: 'left'
    },
    {
      title: 'Productor',
      dataIndex: 'producer',
      render: (producer: IUser) => {
        return producer.name;
      }
    },
    {
      title: 'Fecha Recepción',
      dataIndex: 'reception_date'
    },
    {
      title: 'Kilogramos Ingresados',
      dataIndex: 'total_wet_kgs',
      width: 'auto'
    },
    {
      title: 'Tipo Envase',
      dataIndex: 'packing_type'
    },
    {
      title: 'Cantidad Envases',
      dataIndex: 'amount_of_packages'
    },
    {
      title: 'Planta Proceso',
      dataIndex: 'plant',
      render: (plant: IPlantInfo) => {
        return plant.name;
      }
    },
    {
      title: 'Variedad Fruta',
      dataIndex: 'variety'
    },
    {
      title: 'Guía de Despacho',
      dataIndex: 'guide_number'
    },
    {
      title: 'Nombre Transportista',
      dataIndex: 'carrier_name'
    },
    {
      title: 'Patente Camión',
      dataIndex: 'license_plate'
    },
    {
      title: 'Patente Carro',
      dataIndex: 'truck'
    },
    {
      title: 'Kilogramos Secos',
      dataIndex: 'total_dried_kgs'
    },
    {
      title: 'Humedad Ingreso',
      dataIndex: 'humidity_percentage_in',
      render: (value: number) => {
        if (value) {
          return String(value).concat(' %');
        }
      }
    },
    {
      title: 'Humedad Salida',
      dataIndex: 'humidity_percentage_out',
      render: (value: number) => {
        if (value) {
          return String(value).concat(' %');
        }
      }
    },
    {
      title: 'Rendimiento Secado',
      dataIndex: 'drying_process_efficiency',
      render: (value: number) => {
        if (value) {
          return String(value).concat(' %');
        }
      }
    },
    {
      title: 'Fecha Comienzo Secado',
      dataIndex: 'drying_started_at'
    },
    {
      title: 'Fecha Término Secado',
      dataIndex: 'drying_finished_at'
    },
    {
      title: 'Tiempo de Secado',
      dataIndex: 'drying_total_time',
      render: (value: string) => {
        if (value) {
          value = value.replace('days', 'Días');
          value = value.replace('day', 'Día');
        }
        return value;
      }
    },
    {
      title: 'Análisis de Calidad',
      dataIndex: 'quality_analysis',
      render: (path: string) => {
        if (!path) {
          return 'No Vinculado';
        }
        return (
          <Button key={path} onClick={() => getAnalysis(path)} type="primary">
            Descargar
          </Button>
        );
      }
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'actions',
      width: 'auto',
      fixed: 'right',
      render: (id: number) => (
        <Button key={id} onClick={() => handleAdministrate(id)} type="primary">
          Administrar
        </Button>
      )
    }
  ];

  const [lots, setLots] = useState<ILot[]>([]);
  const [filteredLots, setFilteredLots] = useState<ILot[]>([]);
  const [selectedLot, setSelectedLot] = useState<ILot>();
  const [showAdministrate, setShowAdministrate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showChange, setShowChange] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteAnalysis, setShowDeleteAnalysis] = useState(false);
  const [showConsolidados, setShowConsolidados] = useState(false);
  const navigate = useNavigate();

  const handleAdministrate = (id: number) => {
    lots.map((value, index) => {
      if (value.id === id) {
        setSelectedLot(value);
        setShowAdministrate(true);
      }
    });
  };
  const openEdit = () => {
    setShowAdministrate(false);
    setShowEdit(true);
  };

  const handleEdit = (values: any) => {
    editLot(values)
      .then((res) => {
        if (res.lot) {
          message.success('Exito Actualizando Lote');
          setActionPerformed(!actionPerformed);
          setShowEdit(false);
          return;
        }
        message.error('Error al Actualizar Lote');
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al Comunicarse con el Servidor!');
      });
    setSelectedLot(undefined);
  };

  const openUploadAnalysis = () => {
    setShowAdministrate(false);
    setShowUpload(true);
  };
  const openDeleteAnalysis = () => {
    setShowAdministrate(false);
    setShowDeleteAnalysis(true);
  };
  const openChangeAnalysis = () => {
    setShowAdministrate(false);
    setShowChange(true);
  };
  const openDelete = () => {
    setShowAdministrate(false);
    setShowDelete(true);
  };

  const handleUpload = (fd: FormData) => {
    linkAnalysis(fd, selectedLot?.id ?? -1)
      .then((res) => {
        if (res.lot) {
          message.success('Exito Cargando Archivo!');
          setActionPerformed(!actionPerformed);
          setShowUpload(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al Comunicarse con el Servidor!');
      });
  };

  const getAnalysis = (path: string) => {
    var newWindow = window.open();

    getFile(path)
      .then((res) => {
        if (res.url) {
          if (newWindow === null) {
            return;
          }
          newWindow.location = res.url;
          return;
        }
        message.error('Error al Conseguir Informe');
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error Comunicándose con el Servidor');
      });
  };

  const handleDelete = () => {
    deleteLot(selectedLot?.id ?? -1)
      .then((res) => {
        if (res.success) {
          message.success('Eliminado Correctamente!');
          setShowDelete(false);
          setActionPerformed(!actionPerformed);
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al Comunicarse con el Servidor!');
      });
  };

  const handleDeleteAnalysis = () => {
    deleteAnalysis(selectedLot?.id ?? -1)
      .then((res) => {
        if (res.success) {
          message.success('Eliminado Correctamente!');
          setShowDeleteAnalysis(false);
          setActionPerformed(!actionPerformed);
          return;
        }
        message.error('Error al Eliminar Analisis!');
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error al Comunicarse con el Servidor!');
      });
  };

  const handleCreateConsolidados = () => {
    createConsolidados()
      .then((res) => {
        if (res.success) {
          message.success('Exito creando los consolidados!');
          return;
        }
        message.error('Error Creando los Consolidados!');
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error Comunicándose con el Servidor');
      });
  };

  const filterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = e.target.value;
    const filteredData = lots.filter((entry) => {
      return (
        entry.producer.name.toLowerCase().includes(currValue.toLowerCase()) ||
        String(entry.identifier).includes(currValue)
      );
    });
    setFilteredLots(filteredData);
  };

  useEffect(() => {
    getLots()
      .then((data) => {
        var sortedLots = sortLots(data.lots);
        setLots(sortedLots);
        setFilteredLots(sortedLots);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.description);
          return;
        }
        message.error('Error Comunicándose con el Servidor');
      });
  }, [actionPerformed]);

  const sortLots = (lots: ILot[]) => {
    lots = lots.sort((a, b) => {
      var id_a = Number(a.identifier);
      var id_b = Number(b.identifier);
      if (id_a < id_b) {
        return -1;
      }
      return 1;
    });

    return lots;
  };

  const checkYear = (receptionDate: string, year: number, lot: any) => {
    const [dateStr, timeStr] = receptionDate.split(' ');
    const [day, month, receptionYear] = dateStr.split('/');
    const yearNum = parseInt(receptionYear, 10);
    if (yearNum === year) {
      console.log("True");
      return true;
    }
    return false;

  }

  const currentYear = new Date().getFullYear();

  const items = Array.from(
    { length: currentYear - 2021 },
    (_, index) => {
      const year = 2022 + index;
      return {
        key: year,
        label: `Temporada ${year}`,
        children: <TableSearch
        onRow={(record: ILot, _: number) => {
          return {
            onClick: (e: MouseEvent) => {
              handleAdministrate(record.id);
            }
          };
        }}
        data={filteredLots.filter((lot) => checkYear(lot.reception_date, year, lot))}
        columns={columns}
        filter={filterSearch}
        title="Lotes"
        noMargin={true}
      >
        <>
          <Link to="/admin/create-lot" reloadDocument>
            <Tooltip title={'Crear Nuevo Lote'}>
              <Button className={styles.add} type="primary">
                <AiOutlinePlus className={styles.icon} />
              </Button>
            </Tooltip>
          </Link>
      
          <Tooltip title={'Descargar Excel'}>
            <Button className={styles.add} type="primary" href={`${globalService.apiUrl}/api/v1/lots/generate-excel`}>
              <AiOutlineDownload className={styles.icon} />
            </Button>
          </Tooltip>
          <Tooltip title={'Generar Consolidados Secado'}>
            <Button className={styles.add} type="primary" onClick={() => setShowConsolidados(true)}>
              <AiOutlineFilePdf className={styles.icon} />
            </Button>
          </Tooltip>
        </>
      </TableSearch>,
      };
    }
  );

  


  return (
    <>
    <div className={styles.tabs}>
      <Tabs defaultActiveKey="1" >
        {items.reverse().map((item) => (
          <Tabs.TabPane tab={item.label} key={item.key}>
            {item.children}
          </Tabs.TabPane>
        ))}

      </Tabs>
      </div>
      

     

      <Modal
        title="Crear Consolidados Secado"
        visible={showConsolidados}
        okText="Crear"
        onOk={handleCreateConsolidados}
        onCancel={() => setShowConsolidados(false)}
      >
        <h4>Esta seguro que desea crear todos los consolidados de rendimiento secado?</h4>
      </Modal>

      {selectedLot ? (
        <>
          <AdministrateLotModal
            visible={showAdministrate}
            setVisible={setShowAdministrate}
            handleEdit={openEdit}
            lot={selectedLot}
            handleGetAnalysis={getAnalysis}
            handleUploadAnalysis={openUploadAnalysis}
            handleDelete={openDelete}
            handleDeleteAnalysis={openDeleteAnalysis}
          />
          <UploadAnalysisModal
            visible={showUpload}
            setVisible={setShowUpload}
            lot={selectedLot}
            handleUpload={handleUpload}
          />
          <EditLotModal
            visible={showEdit}
            setVisible={setShowEdit}
            lot={selectedLot}
            onEdit={handleEdit}
            setSelectedLot={setSelectedLot}
          />
          <Modal
            title="Eliminar Lote"
            visible={showDelete}
            okText="Eliminar"
            onOk={handleDelete}
            onCancel={() => setShowDelete(false)}
          >
            <h4>Esta seguro que desea eliminar el lote?</h4>
          </Modal>
          <Modal
            title="Eliminar Analisis"
            visible={showDeleteAnalysis}
            okText="Eliminar"
            onOk={handleDeleteAnalysis}
            onCancel={() => setShowDeleteAnalysis(false)}
          >
            <h4>Esta seguro que desea eliminar el analisis?</h4>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ViewLotsContainer;
