import { http } from '../api/http.provider';
import { IUserInfo } from '../models/user';

interface IUsersResponse {
  users: Array<IUserInfo>;
}

export const getUsers = (): Promise<IUsersResponse> => {
  let url = 'api/v1/users/all';
  return http.get(url, { withCredentials: true }).then(({ data }) => data);
};

export const getProducers = (): Promise<IUsersResponse> => {
  let url = 'api/v1/users/get-producers';
  return http.get(url, { withCredentials: true }).then(({ data }) => data);
};
