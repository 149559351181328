import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { getCurrentUser, isQualityDepartment } from '../../../../helpers/user.helper';
import {
  FaUser,
  FaBuilding,
  FaChartBar,
  FaTools,
  FaUserSecret,
  FaLayerGroup,
  FaTruckMoving,
  FaTruckLoading,
  FaPlusSquare,
  FaWarehouse,
  FaFilePdf
} from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const styles = require('./Sidebar.module.scss');

const LeftSidebar: React.FC = () => {
  const location = useLocation();
  const lastUrl = '/' + location.pathname.split('/').pop() ?? '';
  const [menuItem, setMenuItem] = useState(lastUrl);
  const [collapsed, setCollapsed] = useState(true);
  const currentUser = getCurrentUser() || { role: 6 };

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };

  const handleClick = (menuItem: any) => {
    setMenuItem(menuItem['key']);
  };

  return (
    <Layout.Sider className={styles.sider} collapsed={collapsed} onCollapse={onCollapse}>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[menuItem]}
        className={styles.menu}
        selectedKeys={[menuItem]}
      >
        <Menu.Item key="/crear-lote" onClick={handleClick} disabled={currentUser.role === 6}>
          <NavLink to="/admin/create-lot">
            <FaPlusSquare className="anticon" />
            <span> Ingresar Lote</span>
          </NavLink>
        </Menu.Item>
        ,
        <Menu.Item key="/users" onClick={handleClick}>
          <NavLink to="/admin/users">
            <FaUser className="anticon" />
            <span> Administrar Usuarios</span>
          </NavLink>
        </Menu.Item>
        ,
        <Menu.Item key="/admin/view-lots" onClick={handleClick}>
          <NavLink to="/admin/view-lots">
            <FaTruckMoving className="anticon" />
            <span> Administrar Lotes</span>
          </NavLink>
        </Menu.Item>
        ,
        <Menu.Item key="/admin/plants" onClick={handleClick}>
          <NavLink to="/admin/plants">
            <FaWarehouse className="anticon" />
            <span> Administrar Plantas</span>
          </NavLink>
        </Menu.Item>
        ,
        {isQualityDepartment() ? (
          <Menu.Item key="/admin/analysis" onClick={handleClick}>
            <NavLink to="/admin/analysis">
              <FaFilePdf className="anticon" />
              <span> Generar Informes PDF</span>
            </NavLink>
          </Menu.Item>
        ) : (
          <></>
        )}
      </Menu>
    </Layout.Sider>
  );
};

export default LeftSidebar;
